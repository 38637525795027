
import * as Sentry from '@sentry/browser'
import { defineComponent, reactive, onMounted, toRefs } from 'vue'
import {
  get as getPaymentHistory,
  Billing,
} from '@/api/customer/payment/history'
import { useTranslation } from '@/locales'
import { serviceFactory } from '@/service'

export default defineComponent({
  setup() {
    const items = reactive<{ billings: Billing[] }>({ billings: [] })
    const { billings } = toRefs(items)
    const { t, n, d } = useTranslation()

    onMounted(() => {
      getPaymentHistory().then((res) => {
        billings.value = res.map((service) => {
          const serviceManager = serviceFactory(service.serviceCategory, t, d)

          service.name = serviceManager.getServiceNameForList(
            service.name,
            service.identifier
          )

          return service
        })
      })
    })

    return {
      billings,
      t,
      n,
      d,
    }
  },
})
