<template>
  <div class="flex flex-col">
    <div class="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
      <div class="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
        <div
          class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg"
        >
          <table class="min-w-full divide-y divide-gray-200">
            <thead class="bg-gray-50">
              <tr>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Invoice Date') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Total price without tax') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Tax') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Currency') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Service Name') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Terms') }}
                </th>
                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Amount') }}
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Unit price') }}
                </th>

                <th
                  scope="col"
                  class="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                >
                  {{ t('Payment status') }}
                </th>
              </tr>
            </thead>
            <tbody class="bg-white divide-y divide-gray-200">
              <tr v-for="(billing, index) in billings" :key="index">
                <td
                  v-if="billing.startIndex === index"
                  class="px-6 py-4 whitespace-nowrap"
                  :rowspan="billing.rowspan"
                >
                  <div class="text-sm font-medium text-gray-900 text-left">
                    {{ d(new Date(billing.billingDate), 'ymdhms') }}
                  </div>
                </td>
                <td
                  v-if="billing.startIndex === index"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-900 text-left"
                  :rowspan="billing.rowspan"
                >
                  {{ n(billing.totalPrice, 'currency') }}
                </td>
                <td
                  v-if="billing.startIndex === index"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                  :rowspan="billing.rowspan"
                >
                  {{ n(billing.tax, 'currency') }}
                </td>
                <td
                  v-if="billing.startIndex === index"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                  :rowspan="billing.rowspan"
                >
                  {{ billing.currency }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  {{ billing.name }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  <template
                    v-if="
                      billing.startDate.length > 0 && billing.endDate.length > 0
                    "
                  >
                    {{ d(new Date(billing.startDate), 'ymd') }} -
                    {{ d(new Date(billing.endDate), 'ymd') }}
                  </template>
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  {{ billing.amount }}
                </td>
                <td
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  {{ n(billing.price, 'currency') }}
                </td>

                <td
                  v-if="billing.startIndex === index"
                  :rowspan="billing.rowspan"
                  class="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-left"
                >
                  <p v-for="(confirm, i) in billing.confirms" :key="i">
                    <template v-if="confirm.date.length === 0">
                      {{ t(confirm.result) }}
                    </template>
                    <template v-else>
                      {{ d(new Date(confirm.date), 'ymdhms') }} ({{
                        t(confirm.result)
                      }})
                    </template>
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import * as Sentry from '@sentry/browser'
import { defineComponent, reactive, onMounted, toRefs } from 'vue'
import {
  get as getPaymentHistory,
  Billing,
} from '@/api/customer/payment/history'
import { useTranslation } from '@/locales'
import { serviceFactory } from '@/service'

export default defineComponent({
  setup() {
    const items = reactive<{ billings: Billing[] }>({ billings: [] })
    const { billings } = toRefs(items)
    const { t, n, d } = useTranslation()

    onMounted(() => {
      getPaymentHistory().then((res) => {
        billings.value = res.map((service) => {
          const serviceManager = serviceFactory(service.serviceCategory, t, d)

          service.name = serviceManager.getServiceNameForList(
            service.name,
            service.identifier
          )

          return service
        })
      })
    })

    return {
      billings,
      t,
      n,
      d,
    }
  },
})
</script>
