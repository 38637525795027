import { errorHandler } from '@/api/utils'
import { comma, formatDate } from '@/api/utils'
import { http } from '@/plugins/ky'

export type Billing = {
  startIndex: number
  rowspan: number
  billingDate: string
  totalPrice: number
  name: string
  // term: string
  startDate: string
  expireDate: string
  endDate: string
  amount: number
  price: number
  tax: number
  paymentFrequency: string
  serviceCategory: string
  identifier: string
  currency: string
  confirms: {
    result: string
    date: string
  }[]
}

function getResult(confirm: any) {
  if (confirm.result !== true) {
    return confirm.authorized ? confirm.authorized : 'Failed'
  }

  return 'Completed'
}

export const get = async () =>
  new Promise<Billing[]>((resolve) =>
    http
      .get('customer/payment/history')
      .then(async (r: any) => {
        const result = await r.json()

        let startIndex = 0
        const customersBillings: Billing[] = []

        result.forEach((billing: any) => {
          billing.services.forEach((service: any) => {
            console.log(22222, billing)
            customersBillings.push({
              startIndex,
              rowspan: billing.services.length,
              billingDate: billing.billingDate,
              totalPrice: billing.totalPrice,
              name: service.name,
              startDate: service.startDate,
              expireDate: service.expireDate,
              endDate: service.endDate,
              amount: service.amount,
              price: service.total,
              tax: billing.tax,
              paymentFrequency: service.paymentFrequency,
              serviceCategory: service.serviceCategory,
              identifier: service.identifier,
              currency: billing.currency,
              confirms: billing.confirms.map((confirm: any) => {
                const { result, confirmDate } = confirm
                return {
                  date: confirmDate,
                  result: getResult(confirm),
                }
              }),
            })
          })
          startIndex += billing.services.length
        })
        console.log(3333, customersBillings)
        resolve(customersBillings)
      })
      .catch(async ({ response, message }: any) => {
        console.log(response, message)
        await errorHandler(response, message)
        //TODO:エラーハンドリングきれいにしよう
      })
  )
